<template>
  <div
    class="position-body"
    ref="positionBody"
    :style="style"
  >
    <div class="project-bigscreen">
      <h1 class="page-title">{{pageTitle || '--'}}</h1>
      <div class="flex-box">
        <div class="box-0">
          <div class="shadow-box mb-14">
            <h2 class="w-title-gradients">
              {{filtroDataUpdate?'昨日销售数据':'招商数据更新'}}
              <el-tooltip
                class="item"
                effect="dark"
                :content="filtroDataUpdate?'显示昨日项目内店铺销售数据':'项目最新更新招商跟进状态的品牌'"
                placement="right"
              >
                <span>
                  <i class="icon-exclamation"></i>
                </span>
              </el-tooltip>
            </h2>
            <div class="tbsCommon">
              <span
                :class="{'activeSpan': filtroDataUpdate===0}"
                @click="handleDataUpdate(0)"
              >招商</span>
              <span
                :class="{'activeSpan': filtroDataUpdate===1}"
                @click="handleDataUpdate(1)"
              >销售</span>
            </div>
            <el-carousel
              class="box-top-inside"
              v-if="dataUpdateList.length>0"
              direction="vertical"
              indicator-position="none"
              :autoplay="true"
            >
              <el-carousel-item
                v-for="item in dataUpdateList"
                :key="item.id"
              >
                <div
                  v-for="ele in item"
                  :key="ele.brandName"
                >
                  <p class="data-card-title">{{ele.changeStateTime}}<span v-if="filtroDataUpdate === 0">{{' ' + ele.changeUserName || ' --'}}</span></p>
                  <div class="data-card">
                    <img
                      :src="ele.brandLogo"
                      width="64"
                      height="64"
                      alt=""
                      class="fl"
                    >
                    <h3>{{ele.brandName}}</h3>
                    <p>
                      <span class="tag">
                        <span class="fs-10">{{ele.brandLastIndustry}}</span>
                      </span>
                    </p>
                    <p
                      class="info"
                      :title="ele.projectNameAndBerth"
                    >
                      {{ele.projectNameAndBerth | lenLimit}}
                    </p>
                    <span
                      class="state"
                      v-if="!filtroDataUpdate"
                    >
                      <i
                        class="icon-wrap"
                        :style="{background: ele.color}"
                      ></i>
                      <i
                        class="icon-box"
                        :style="{background: ele.color}"
                      ></i>
                      {{ele.currentFollowState}}
                    </span>
                    <span
                      class="state"
                      v-else
                    >
                      {{ele.salesAmount}}
                    </span>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div
              class="details-no-data mt80"
              v-show="dataUpdateList.length === 0"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
          <div class="shadow-box">
            <h2 class="w-title-gradients">
              签约租金趋势分析
              <el-tooltip
                class="item"
                effect="dark"
                content="年度租金统计及最近7个月已出租铺位月租金趋势"
                placement="right"
              >
                <span>
                  <i class="icon-exclamation"></i>
                </span>
              </el-tooltip>
            </h2>
            <div class="box-middle-inside">
              <ul class="info-ul">
                <li class="border-r">
                  <p class="info-p">
                    本年度租金(万)<br>
                    <span class="num-span">{{rentTrendObj.rentThisYear | numFilter}}</span>
                  </p>
                </li>
                <li>
                  <p class="info-p">
                    上年度租金(万)<br>
                    <span class="num-span">{{rentTrendObj.rentLastYear | numFilter}}</span>
                  </p>
                </li>
              </ul>
              <div
                v-show="isShowRentTrend"
                id="chartsRentTrend"
                class="charts-box"
              ></div>
              <div
                class="details-no-data mt-60"
                v-show="!isShowRentTrend"
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box-1">
          <mapScreen @selectPath="selectPath"></mapScreen>
        </div>
        <div class="box-2">
          <div class="shadow-box mb-14">
            <h2 class="w-title-gradients">
              经营数据概览
              <el-tooltip
                class="item"
                effect="dark"
                content="项目经营相关数据统计"
                placement="right"
              >
                <span>
                  <i class="icon-exclamation"></i>
                </span>
              </el-tooltip>
            </h2>
            <ul class="info-ul multi-info-ul box-top-inside">
              <li>
                <p>昨日销售(万)</p>
                <span class="num-span">{{salesDataObj.salesYesterDay | numFilter}}</span>
                <p class="second-info">前日销售(万)：<span>{{salesDataObj.salesDaysAgo}}</span></p>
              </li>
              <li>
                <p>本月销售(万)</p>
                <span class="num-span">{{salesDataObj.salesCurrentMonth | numFilter}}</span>
                <p class="second-info">上月销售(万)：<span>{{salesDataObj.salesLastMonth}}</span></p>
              </li>
              <li>
                <p>上月已收(万)</p>
                <span class="num-span">{{salesDataObj.paidLastMonth | numFilter}}</span>
                <p class="second-info">上月应收(万)：<span>{{salesDataObj.receivableLastMonth | numFilter}}</span></p>
              </li>
              <li>
                <p>上月费用收缴率</p>
                <span class="num-span">{{salesDataObj.captureLastMonth | numFilter | percentNum}}</span>
              </li>
              <li>
                <p>本月新签商户</p>
                <span class="num-span">{{salesDataObj.addMerchantsThisMonth | numFilter}}</span>
              </li>
              <li>
                <p>本月撤场商户</p>
                <span class="num-span">{{salesDataObj.reduceMerchantsThisMonth | numFilter}}</span>
              </li>
            </ul>
          </div>
          <div class="shadow-box">
            <h2 class="w-title-gradients">
              销售同比分析
              <el-tooltip
                class="item"
                effect="dark"
                content="统计最近7日及每月销售数据同比分析"
                placement="right"
              >
                <span>
                  <i class="icon-exclamation"></i>
                </span>
              </el-tooltip>
            </h2>
            <div class="tbsCommon">
              <span
                :class="{'activeSpan': filtroSales===0}"
                @click="changeSalesTbs(0)"
              >最近</span>
              <span
                :class="{'activeSpan': filtroSales===1}"
                @click="changeSalesTbs(1)"
              >同比</span>
            </div>
            <div class="box-middle-inside">
              <ul class="info-ul">
                <li class="border-r">
                  <p class="info-p">
                    本月销售额(万)<br>
                    <span class="num-span">{{salesObj.salesThisMonth | numFilter}}</span>
                  </p>
                </li>
                <li>
                  <p>
                    本年销售额(万)<br>
                    <span class="num-span">{{salesObj.salesThisYear | numFilter}}</span>
                  </p>
                </li>
              </ul>
              <div
                v-show="filtroSales===0"
                id="chartsLineSales"
                class="charts-box"
              ></div>
              <div
                class="details-no-data mt-60"
                v-show="filtroSales===0 && !isShowSalesLine"
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
              <div
                v-show="filtroSales===1 && isShowSalesBar"
                id="chartsBarSales"
                class="charts-box"
              ></div>
              <div
                class="details-no-data mt-60"
                v-show="filtroSales===1 && !isShowSalesBar"
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-box bottom-flex-wrap">
        <div class="shadow-box box-0">
          <h2 class="w-title-gradients">
            入驻品牌分析
            <el-tooltip
              class="item"
              effect="dark"
              :content="!filtroBrand?'已签约品牌（生效中的）按品牌业态显示入驻占比及数量':'已签约品牌（生效中的）按连锁方式显示入驻占比及数量'"
              placement="right"
            >
              <span>
                <i class="icon-exclamation"></i>
              </span>
            </el-tooltip>
          </h2>
          <div class="tbsCommon">
            <span
              :class="{'activeSpan': filtroBrand===0}"
              @click="changeBrandTbs(0)"
            >业态</span>
            <span
              :class="{'activeSpan': filtroBrand===1}"
              @click="changeBrandTbs(1)"
            >连锁</span>
          </div>
          <ul class="info-ul">
            <li class="border-r">
              <p class="info-p">
                企业品牌库资源<br>
                <span class="num-span">{{brandObj.allBrandNums}}</span>
              </p>
            </li>
            <li>
              <p class="info-p">
                当前进驻品牌<br>
                <span class="num-span">{{brandObj.currentSettledNums}}</span>
              </p>
            </li>
          </ul>
          <div
            v-show="filtroBrand===0 && isShowBrandChartsPie"
            id="chartsPieBrand"
            class="charts-box"
          ></div>
          <div
            class="details-no-data mt-60"
            v-show="filtroBrand===0 && !isShowBrandChartsPie"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>抱歉，暂无数据</p>
          </div>
          <div
            v-show="filtroBrand===1 && isShowBrandChartsBar"
            id="chartsBarBrand"
            class="charts-box"
          ></div>
          <div
            class="details-no-data mt-60"
            v-show="filtroBrand===1 && !isShowBrandChartsBar"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>抱歉，暂无数据</p>
          </div>
        </div>
        <div
          class="shadow-box box-1"
          v-loading="dataRankingLoading"
        >
          <h2 class="w-title-gradients">
            {{dataRankingTbsList[filtroDataRanking]}}数据排行
            <el-tooltip
              class="item"
              effect="dark"
              content="本年度累计收入排行"
              placement="right"
            >
              <span>
                <i class="icon-exclamation"></i>
              </span>
            </el-tooltip>
          </h2>
          <div class="tbsCommon">
            <span
              v-for="(item, index) in dataRankingTbsList"
              :key="index"
              :class="{'activeSpan': filtroDataRanking===index}"
              @click="handleDataRankingTbs(index)"
            >{{item}}</span>
          </div>
          <el-table
            v-if="
            dataRankingObj.dataRankingList && dataRankingObj.dataRankingList.length !== 0 &&
            this.dataRankingTbsList[this.filtroDataRanking]!=='店铺'"
            :data="dataRankingObj.dataRankingList"
            class="data-ranking-tbs"
          >
            <el-table-column
              v-if="mapFlag===3"
              prop="floorFName"
              label="楼宇"
              max-width="180"
              min-width="140"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="name"
              :label="this.dataRankingTbsList[this.filtroDataRanking] | tbsName"
              :width="this.dataRankingTbsList[this.filtroDataRanking]==='项目'?180:''"
              :class-name="this.dataRankingTbsList[this.filtroDataRanking] === '项目' ? 'w-180' : ''"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              v-if="
                dataRankingTbsList[filtroDataRanking]==='省份' ||
                dataRankingTbsList[filtroDataRanking]==='城市'
              "
              prop="projectsNumbers"
              :label="'项目数量'"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              v-else
              prop="unitNumberNum"
              label="铺位数量"
              width="90"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="buildAreaTotal"
              label="总建筑面积"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="rentalRate"
              label="入驻率"
              width=""
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="collectionRateLastMonth"
              label="上月费用收缴率"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="salesYesterDay"
              label="昨日销售额"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="salesThisMonth"
              label="本月销售额"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="salesLastMonth"
              label="上月销售额"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="rentLastMonth"
              label="上月租金"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="incomeThisYear"
              label="年度累计收入"
              sortable
              width="130"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{scope.row.incomeThisYear?scope.row.incomeThisYear + '万':''}}
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="
            dataRankingObj.dataRankingList && dataRankingObj.dataRankingList.length !== 0 &&
            this.dataRankingTbsList[this.filtroDataRanking]==='店铺'"
            :data="dataRankingObj.dataRankingList"
            class="data-ranking-tbs"
          >
            <el-table-column
              prop="unitNumber"
              label="铺位号"
              width=""
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="店铺名称"
              width=""
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              v-if="mapFlag===3"
              prop="floorFName"
              label="楼层"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              v-else
              prop="brandName"
              label="品牌"
              width=""
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="contractArea"
              label="合同面积"
              width=""
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="collectionRateLastMonth"
              label="上月费用收缴率"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="salesYesterDay"
              label="昨日销售额"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="salesThisMonth"
              label="本月销售额"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="salesLastMonth"
              label="上月销售额"
              width=""
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="rentLastMonth"
              label="上月租金"
              width=""
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="incomeThisYear"
              label="年度累计收入"
              sortable
              width="130"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{scope.row.incomeThisYear + '万'}}
              </template>
            </el-table-column>
          </el-table>
          <div
            class="details-no-data mt80"
            v-show="dataRankingObj.dataRankingList && dataRankingObj.dataRankingList.length === 0"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>抱歉，暂无数据</p>
          </div>
        </div>
        <div class="shadow-box box-2">
          <h2 class="w-title-gradients">
            收入名目分析
            <el-tooltip
              class="item"
              effect="dark"
              content="本月应收账单的名目占比分析"
              placement="right"
            >
              <span>
                <i class="icon-exclamation"></i>
              </span>
            </el-tooltip>
          </h2>
          <div
            v-if="isShowRentCharts"
            id="chartsPieRent"
            class="charts-box"
          ></div>
          <div
            class="details-no-data mt-60"
            v-else
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>抱歉，暂无数据</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapScreen from '@/views/biModules/components/map.vue'
import echarts from 'echarts'
import api from '@/api'
export default {
  name: 'projectBigScreen',
  components: {
    mapScreen
  },
  data () {
    return {
      loading: true,
      isHidden: false,
      pageTitle: '',
      filtroDataUpdate: 0,
      filtroSales: 0,
      filtroBrand: 0,
      filtroDataRanking: 0,
      filtroRent: 0,
      dataRankingTbsList: ['省份', '城市', '项目'],
      mapFlag: 0,
      dataUpdateList: [''],
      rentTrendObj: {},
      brandObj: {
        allBrandNums: 0,
        currentSettledNums: 0
      },
      chartsLineSales: {},
      chartsBarSales: {},
      dataRankingObj: {
        dataRankingName: '',
        dataRankingList: [' ']
      },
      dataRankingLoading: false,
      salesDataObj: {
        salesLastMonth: '',
        receivableLastMonth: '',
        paidLastMonth: '',
        captureLastMonth: '',
        addMerchantsThisMonth: '',
        reduceMerchantsThisMonth: ''
      },
      salesObj: {
        salesThisMonth: 0,
        salesThisYear: 0
      },
      chartsCirculation: null,
      chartsCirculationRent: null,
      isShowRentCharts: true,
      isShowBrandChartsPie: true,
      isShowBrandChartsBar: true,
      isShowRentTrend: true,
      isShowSalesBar: true,
      isShowSalesLine: true,
      chartsPieBrand: null,
      chartsBarBrand: {},
      chartsPieRent: null,
      dataRefreshDataUpdate: null,
      dataRefreshRentTrend: null,
      path: '全国',
      scale: 1,
      style: ''
    }
  },
  mounted () {
    this.getDPurl()
    this.chartsBarBrand = echarts.init(document.getElementById('chartsBarBrand'))
    document.body.style.overflowY = 'hidden'
    document.body.style.overflowX = 'hidden'
    const w = document.body.clientWidth
    const h = window.innerHeight
    let scale = w / 1920
    const left = -(1920 - w) / 2
    const top = -(1080 - h) / 2
    if (h < scale * 1080) {
      scale = h / 1080
      this.style = `transform: scale(${scale}); margin-top: ${top}px;margin-left: ${left}px`
    } else {
      this.style = `transform: scale(${scale}); margin-left: ${left}px; top: 50%;`
    }
    window.addEventListener('resize', () => {
      this.chartsPieBrand.resize()
      this.chartsBarBrand.resize()
      this.$nextTick(() => {
        const w = document.body.clientWidth
        const h = window.innerHeight
        let scale = w / 1920
        const left = -(1920 - w) / 2
        const top = -(1080 - h) / 2
        if (h < scale * 1080) {
          scale = h / 1080
          this.style = `transform: scale(${scale}); margin-top: ${top}px;margin-left: ${left}px`
        } else {
          this.style = `transform: scale(${scale}); margin-left: ${left}px; top: 50%;`
        }
      })
      // if (document.body.clientWidth < 1920) {
      //   this.scaleVal = ((document.documentElement.clientWidth || document.body.clientWidth) / 1920)
      //   this.$nextTick(() => {
      //     setTimeout(() => {
      //       // this.bodyLeft = (this.$refs.positionBody.offsetWidth - (1920 * this.scaleVal)) / 2
      //     }, 300)
      //   })
      // } else {
      //   this.scaleVal = ((document.documentElement.clientHeight || document.body.clientHeight) / 1080)
      // }
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     // this.bodyLeft = (this.$refs.positionBody.offsetWidth - (1920 * this.scaleVal)) / 2
      //     this.loading = false
      //   }, 300)
      // })
      // console.log(document.body.clientWidth, document.body.clientHeight)
      // if (document.body.clientWidth < 1500 && document.body.clientHeight === 1080) {
      //   setTimeout(() => {
      //     this.bodyLeft = -240
      //     this.bodyTop = 135
      //   }, 500)
      // }
      // if (document.body.clientWidth < 1500 && document.body.clientHeight < 1080) {
      //   setTimeout(() => {
      //     this.bodyLeft = -240
      //     this.bodyTop = 67
      //   }, 500)
      // }
    })
    // this.scaleHeight = ((document.documentElement.clientHeight || document.body.clientHeight) / 1080)
    // if (document.body.clientWidth < 1920) {
    //   this.scaleVal = ((document.documentElement.clientWidth || document.body.clientWidth) / 1920)
    //   this.$nextTick(() => {
    //     setTimeout(() => {
    //       // this.bodyLeft = (this.$refs.positionBody.offsetWidth - (1920 * this.scaleVal)) / 2
    //     }, 300)
    //   })
    // } else {
    //   this.scaleVal = ((document.documentElement.clientHeight || document.body.clientHeight) / 1080)
    // }
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.isHidden = true
    //     // this.bodyLeft = (this.$refs.positionBody.offsetWidth - (1920 * this.scaleVal)) / 2
    //     this.loading = false
    //   }, 300)
    // })
    // this.scaleVal.toFixed(4)
    // if (document.body.clientWidth === 1440 && document.body.clientHeight === 1080) {
    //   setTimeout(() => {
    //     this.bodyLeft = -240
    //     this.bodyTop = 135
    //   }, 500)
    // }
    // if (document.body.clientWidth < 1500 && document.body.clientHeight < 1080) {
    //   setTimeout(() => {
    //     this.bodyLeft = -240
    //     this.bodyTop = 67
    //   }, 500)
    // }
  },
  computed: {
  },
  filters: {
    numFilter (num) {
      if (num) {
        return num
      } else {
        return 0
      }
    },
    percentNum (num) {
      return num.toFixed(2) + '%'
    },
    lenLimit (str) {
      if (str.length > 14) {
        str = str.substring(0, 14) + '...'
      }
      return str
    },
    tbsName (name) {
      let res = ''
      if (name === '楼层') {
        res = '楼层'
      } else if (name === '店铺') {
        res = '铺位号'
      } else {
        res = '名称'
      }
      return res
    }
  },
  methods: {
    numFilter (num) {
      const regPos = /^\d+(\.\d+)?$/ // 非负浮点数
      const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ // 负浮点数
      if (regPos.test(num) || regNeg.test(num)) {
        return num
      } else {
        return 0
      }
    },
    getDPurl () {
      this.axios.post(api.getDPurl).then(res => {
        if (res.data.data) {
          const len = res.data.data.filter(item => {
            return item.name === '项目经营大屏'
          })
          if (len.length === 0) {
            this.$message({
              type: 'warning',
              message: '抱歉，暂无权限访问此应用模块！'
            })
            this.$router.push({ path: '/' })
          }
        }
      })
    },
    selectPath (val) {
      this.path = val
      clearInterval(this.chartsCirculationRent)
      clearInterval(this.chartsCirculation)
      if (this.chartsPieBrand) {
        this.chartsPieBrand.dispose()
        this.chartsPieBrand = null
      }
      if (this.chartsPieRent) {
        this.chartsPieRent.dispose()
        this.chartsPieRent = null
      }
      this.getTitle()
      this.handleRentTrend()
      this.handleBrand()
      this.getDataBrand()
      this.filtroDataRanking = 0
      this.handleDataRanking()
      this.handleSalesData()
      this.handleSales(this.filtroSales)
      this.handleRent()
      this.handleDataUpdate(this.filtroDataUpdate)
      if (this.dataRefreshDataUpdate) {
        clearInterval(this.dataRefreshDataUpdate)
        this.dataRefreshDataUpdate = null
      }
      this.dataRefreshDataUpdate = setInterval(() => { // 需求：无操作时每分钟刷新一次 模块：数据更新
        this.handleDataUpdate(this.filtroDataUpdate)
      }, 60000)
      if (this.dataRefreshRentTrend) {
        clearInterval(this.dataRefreshRentTrend)
        this.dataRefreshRentTrend = null
      }
      this.dataRefreshRentTrend = setInterval(() => { // 需求：无操作时每分钟刷新一次 模块：数据更新
        this.handleRentTrend()
        this.handleBrand()
        this.getDataBrand()
        this.handleDataRanking(this.filtroDataRanking)
        this.handleSalesData()
        this.handleSales(this.filtroSales)
        this.handleRent()
      }, 600000)
    },
    getTitle () {
      this.pageTitle = ''
      const params = {
        path: this.path
      }
      this.axios.post(api.title, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.pageTitle = resData
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleDataUpdate (num) {
      this.filtroDataUpdate = num
      let resData = []
      const arrParent = []
      let arrChild = []
      if (num === 0) {
        const params = {
          maxScreenLevel: this.path
        }
        this.axios.post(api.aiDataUpdate, params).then(res => {
          if (res.data.code === 0) {
            resData = res.data.data
            resData = resData.map((item) => {
              if (item.currentFollowState === '待跟进') {
                item.color = '#FFA134'
              } else if (item.currentFollowState === '已联系') {
                item.color = '#3494FF'
              } else if (item.currentFollowState === '已看场') {
                item.color = '#26CBE9'
              } else if (item.currentFollowState === '已报价') {
                item.color = '#FEF054'
              } else if (item.currentFollowState === '已预定') {
                item.color = '#FE7429'
              } else if (item.currentFollowState === '已签约') {
                item.color = '#16DA64'
              } else if (item.currentFollowState === '已搁置') {
                item.color = '#C750FF'
              } else {
                item.color = '#999999'
              }
              return item
            })
            for (let i = 0; i < resData.length; i++) {
              const element = resData[i]
              arrChild.push(element)
              if (arrChild.length === 2 || i === resData.length - 1) {
                arrParent.push(arrChild)
                arrChild = []
              }
            }
            this.dataUpdateList = arrParent
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      } else if (num === 1) {
        const params = {
          path: this.path
        }
        this.axios.post(api.getYesterDaySalesDataList, params).then(res => {
          if (res.data.code === 0) {
            resData = res.data.data.map(item => {
              return {
                changeStateTime: item.salesTime,
                changeUserName: item.cashierName,
                brandLogo: item.brandLogoUrl,
                brandName: item.brandName,
                brandLastIndustry: item.formatsName,
                projectNameAndBerth: item.mallName + item.unitNumber,
                salesAmount: item.salesAmount
              }
            })
            for (let i = 0; i < resData.length; i++) {
              const element = resData[i]
              arrChild.push(element)
              if (arrChild.length === 2 || i === resData.length - 1) {
                arrParent.push(arrChild)
                arrChild = []
              }
            }
            this.dataUpdateList = arrParent
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    handleRentTrend () { // 签约租金趋势分析
      const params = {
        path: this.path
      }
      this.axios.post(api.getRentValueVo, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.rentTrendObj = resData
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      this.axios.post(api.getRentLineChart, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData.series) {
            this.isShowRentTrend = true
            let series = []
            const legend = {
              data: []
            }
            series = resData.series.map((item, index) => {
              legend.data.push({
                name: item.name
              })
              // legend.icon = item.legendShape
              item.type = 'line'
              item.areaStyle = {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(' + this.handleColor(item.color) + ',1)'
                  }, {
                    offset: 0.8,
                    color: 'rgba(' + this.handleColor(item.color) + ',.2)'
                  }], false),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur: 10
                }
              }
              return item
            })
            const chartsRentTrend = echarts.init(document.getElementById('chartsRentTrend'))
            window.addEventListener('resize', () => {
              chartsRentTrend.resize()
            })
            chartsRentTrend.setOption(this.chartsOptionLine())
            chartsRentTrend.setOption({
              legend: legend,
              xAxis: {
                data: resData.categories
              },
              series: series
            })
          } else {
            this.isShowRentTrend = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    chartsOptionLine () {
      const option = {
        legend: {
          itemHeight: 10,
          itemWidth: 10,
          bottom: 0,
          icon: 'rect',
          textStyle: {
            color: 'rgba(255, 255, 255, .45)'
          }
        },
        grid: {
          top: '38',
          right: '10',
          bottom: '50',
          left: '50'
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,.5)',
          formatter: (params) => {
            let html = params[0].name + '<br>'
            for (let i = 0; i < params.length; i++) {
              html += params[i].marker + params[i].seriesName + ' ' + params[i].data + '万<br>'
            }
            return html
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          axisLabel: {
            color: 'rgba(255, 255, 255, .45)'
          },
          axisPointer: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          },
          data: []
        },
        yAxis: {
          name: '',
          nameTextStyle: {
            color: 'rgba(255, 255, 255, .45)'
          },
          type: 'value',
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.1)'
            }
          },
          axisLabel: {
            color: 'rgba(255, 255, 255, .45)',
            formatter: '{value} 万'
          }
        },
        series: []
      }
      return option
    },
    handleColor (value) {
      // 16进制颜色值的正则
      const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      // 把颜色值变成小写
      let color = value.toLowerCase()
      if (reg.test(color)) {
        // 如果只有三位的值，需变成六位，如：#fff => #ffffff
        if (color.length === 4) {
          let colorNew = '#'
          for (let i = 1; i < 4; i += 1) {
            colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1))
          }
          color = colorNew
        }
        // 处理六位的颜色值，转为RGB
        const colorChange = []
        for (let i = 1; i < 7; i += 2) {
          colorChange.push(parseInt('0x' + color.slice(i, i + 2)))
        }
        return colorChange.join(',')
      } else {
        return color
      }
    },
    handleSalesData () {
      const params = {
        path: this.path
      }
      this.axios.post(api.getSalesDataOverview, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData) {
            resData.captureLastMonth = resData.captureLastMonth * 100
            this.salesDataObj = resData
          } else {
            this.salesDataObj = {
              salesLastMonth: '',
              receivableLastMonth: '',
              paidLastMonth: '',
              captureLastMonth: '',
              addMerchantsThisMonth: '',
              reduceMerchantsThisMonth: ''
            }
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    changeSalesTbs (num) {
      this.filtroSales = num
      this.$nextTick(() => {
        this.chartsLineSales.resize()
        this.chartsBarSales.resize()
      })
    },
    handleSales () { // 销售同比分析
      const params = {
        path: this.path
      }
      this.axios.post(api.getSalesFigures, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData) {
            this.salesObj = resData
          } else {
            this.salesObj = {
              salesThisMonth: 0,
              salesThisYear: 0
            }
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      this.axios.post(api.getSalesAnalysisLineChart, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData.series) {
            this.isShowSalesLine = true
            let series = []
            const legend = {
              data: []
            }
            series = resData.series.map((item, index) => {
              legend.data.push({
                name: item.name
              })
              item.type = 'line'
              item.areaStyle = {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(' + this.handleColor(item.color) + ',1)'
                  }, {
                    offset: 0.8,
                    color: 'rgba(' + this.handleColor(item.color) + ',.2)'
                  }], false),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur: 10
                }
              }
              return item
            })
            this.chartsLineSales = echarts.init(document.getElementById('chartsLineSales'))
            window.addEventListener('resize', () => {
              this.chartsLineSales.resize()
            })
            this.chartsLineSales.setOption(this.chartsOptionLine())
            this.chartsLineSales.setOption({
              legend: legend,
              xAxis: {
                data: resData.categories
              },
              series: series
            })
          } else {
            this.isShowSalesLine = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      // 柱状图
      this.axios.post(api.getYearOnYearSalesAnalysis, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData.series) {
            this.isShowSalesBar = true
            const series = resData.series.map(item => {
              item.type = 'bar'
              return item
            })
            const option = {
              legend: {
                itemHeight: 10,
                itemWidth: 10,
                bottom: 0,
                icon: 'rect',
                textStyle: {
                  color: 'rgba(255, 255, 255, .45)'
                }
              },
              grid: {
                top: '38',
                right: '10',
                bottom: '50',
                left: '80'
              },
              tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                  let html = params[0].name + '<br>'
                  for (let i = 0; i < params.length; i++) {
                    html += params[i].marker + params[i].seriesName + ' ' + params[i].data + '万<br>'
                  }
                  return html
                }
              },
              xAxis: {
                type: 'category',
                axisTick: {
                  show: false
                },
                axisLabel: {
                  color: 'rgba(255, 255, 255, .45)'
                },
                data: resData.categories
              },
              yAxis: {
                name: '万',
                nameTextStyle: {
                  color: 'rgba(255, 255, 255, .45)'
                },
                type: 'value',
                axisTick: {
                  show: false
                },
                splitLine: {
                  lineStyle: {
                    color: 'rgba(255, 255, 255, 0.1)'
                  }
                },
                axisLabel: {
                  color: 'rgba(255, 255, 255, .45)'
                }
              },
              series: series
            }
            this.chartsBarSales = echarts.init(document.getElementById('chartsBarSales'))
            window.addEventListener('resize', () => {
              this.chartsBarSales.resize()
            })
            this.chartsBarSales.setOption(option)
          } else {
            this.isShowSalesBar = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataBrand () {
      const params = {
        maxScreenLevel: this.path
      }
      this.axios.post(api.brandsData, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData && resData.length) {
            this.brandObj = resData[0]
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    changeBrandTbs (num) {
      this.filtroBrand = num
      if (num === 1) {
        this.$nextTick(() => {
          this.chartsBarBrand.resize()
        })
      } else {
        if (this.chartsPieBrand) {
          this.chartsPieBrand.dispose()
          this.chartsPieBrand = null
        }
        this.handleBrand()
      }
    },
    handleBrand () { // 入驻品牌分析
      if (this.chartsCirculation) {
        clearInterval(this.chartsCirculation)
        this.chartsCirculation = null
      }
      const params = {
        maxScreenLevel: this.path
      }
      // 业态
      this.axios.post(api.industryLoop, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData && resData.length) {
            this.isShowBrandChartsPie = true
            this.$nextTick(() => {
              this.chartsPieBrand = echarts.init(document.getElementById('chartsPieBrand'))
              this.chartsPieBrand.setOption(this.chartsOptionPie())
              window.addEventListener('resize', () => {
                this.chartsPieBrand.resize()
              })
              const _this = this
              let isSet = true // 判断是否设置了循环高亮定时器
              let seriesData = [] // echarts series数据
              seriesData = resData.map(item => { // series data 赋值
                return {
                  name: item.industryName,
                  value: item.settledBrandNums,
                  percent: item.ratio
                }
              })
              const maxArrList = resData.filter((item, index) => { // 占比最高的列表，可能单个最高或多个最高
                item.index = parseInt(index)
                item.percent = item.ratio
                return item.maxOrNot === 1
              })
              let maxArr = {}
              if (maxArrList && maxArrList.length) { // 如果存在占比最高的列表，那么取第一个，无论单个或多个
                maxArr = maxArrList.filter((item, index) => {
                  item.name = item.industryName
                  item.value = item.settledBrandNums
                  item.percent = item.ratio
                  return index === 0
                })[0]
              } else { // 主要处理数据异常的情况
                maxArr = {
                  index: 0
                }
              }
              let currentIndex = maxArr.index // 当前高亮的项的序号，默认高亮占比最高的项
              this.chartsPieBrand.setOption({
                legend: {
                  // type: 'scroll',
                  top: 'center'
                },
                series: {
                  data: seriesData
                }
              })
              // 数据处理完毕，开始执行动作
              this.chartsPieBrand.dispatchAction({ // 默认高亮占比最高的项
                type: 'highlight',
                dataIndex: maxArr.index
              })
              let lastIndex = 0
              const chartHover = function () { // 循环高亮
                if (seriesData.length !== 1) { // 只有一条的时候，一直高亮唯一一条
                  if (currentIndex + 1 === seriesData.length) { // 序号高位接低位
                    currentIndex = 0
                    lastIndex = seriesData.length - 1
                  } else {
                    currentIndex++
                    lastIndex = currentIndex - 1
                  }
                  _this.chartsPieBrand.dispatchAction({
                    type: 'downplay',
                    dataIndex: lastIndex
                  })
                  _this.chartsPieBrand.dispatchAction({
                    type: 'highlight',
                    dataIndex: currentIndex
                  })
                }
              }
              this.chartsCirculation = setInterval(chartHover, 3000) // 默认启动的定时循环高亮
              // 事件
              // 鼠标移入
              this.chartsPieBrand.on('mouseover', params => {
                clearInterval(this.chartsCirculation) // 停止循环高亮
                _this.chartsPieBrand.dispatchAction({
                  type: 'downplay',
                  dataIndex: currentIndex
                })
                isSet = false
                currentIndex = params.dataIndex
                this.chartsPieBrand.dispatchAction({
                  type: 'highlight',
                  dataIndex: params.dataIndex
                })
              })
              // 鼠标移出
              this.chartsPieBrand.on('mouseout', params => {
                if (this.chartsCirculation) {
                  clearInterval(this.chartsCirculation)
                  isSet = false
                }
                this.chartsPieBrand.dispatchAction({ // 持续高亮
                  type: 'highlight',
                  dataIndex: currentIndex
                })
                if (!isSet) {
                  this.chartsCirculation = setInterval(chartHover, 3000)
                  isSet = true
                }
              })
            })
          } else {
            this.isShowBrandChartsPie = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      // 连锁
      this.axios.post(api.chainTypeRatio, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData && resData.length) {
            const numList = []
            const seriesData = resData.map(item => {
              numList.push(item.settledBrandNums)
              return item.ratio
            })
            if (!this.chartsBarBrand) {
              setTimeout(() => {
                this.chartsBarBrand.resize()
              }, 50)
            }
            this.isShowBrandChartsBar = true
            const option = {
              title: {
                text: '入驻数量',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 500,
                  color: '#70747d'
                },
                right: '3%',
                top: '0%',
                subtext: ''
              },
              tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                  return params[0].marker + params[0].name + ' ' + params[0].data + '%'
                },
                axisPointer: {
                  type: 'shadow'
                }
              },
              legend: {
                show: false
              },
              grid: {
                top: '8%',
                left: '3%',
                right: '15%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'value',
                splitLine: false,
                axisTick: {
                  show: false
                },
                axisLine: {
                  show: false
                },
                axisLabel: {
                  formatter: '{value}%',
                  color: 'rgba(255, 255, 255, .45)'
                },
                max: 100
              },
              yAxis: [
                {
                  type: 'category',
                  axisTick: {
                    show: false
                  },
                  axisLabel: {
                    color: 'rgba(255, 255, 255, .45)'
                  },
                  data: resData.map(item => {
                    return item.chainType
                  })
                },
                {
                  type: 'category',
                  axisTick: {
                    show: false
                  },
                  axisLabel: {
                    margin: 70,
                    formatter: (value, index) => {
                      return numList[index]
                    },
                    color: 'rgba(255, 255, 255, .45)'
                  },
                  data: resData.map(item => {
                    return item.chainType
                  })
                }
              ],
              series: {
                name: '',
                type: 'bar',
                barWidth: 12,
                label: {
                  show: true, // 开启显示
                  formatter: function (params) {
                    let html = ''
                    html = params.data + '% '
                    return html
                  },
                  position: 'right',
                  textStyle: { // 数值样式
                    color: 'rgba(255, 255, 255, .9)',
                    fontSize: 14
                  }
                },
                data: seriesData
              }
            }
            this.chartsBarBrand.setOption(option)
          } else {
            this.isShowBrandChartsBar = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    chartsOptionPie () {
      const option = {
        title: {
          show: false
        },
        tooltip: {
          show: false
        },
        legend: {
          show: true,
          width: 205,
          itemWidth: 10,
          itemHeight: 10,
          right: 10,
          itemGap: 8,
          top: 'middle',
          textStyle: {
            color: 'rgba(255, 255, 255, 1)'
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            // 自定义颜色
            // color: '',
            radius: ['58%', '70%'], // 饼图大小
            center: ['28%', '55%'],
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              type: 'text',
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold',
                formatter: [
                  '{b|{b}}',
                  '{c|{c}}',
                  '{d|{d}%}'
                ].join('\n'),
                rich: {
                  b: {
                    color: 'rgba(255,255,255,.7)',
                    lineHeight: 26,
                    fontSize: 16
                  },
                  c: {
                    color: '#fff',
                    lineHeight: 36,
                    fontWeight: 700,
                    fontSize: 30
                  },
                  d: {
                    color: 'rgba(255,255,255,.9)',
                    fontSize: 16
                  }
                }
              }
            },
            avoidLabelOverlap: false,
            legendHoverLink: false, // 移入leged不显示在中间
            textAlign: 'center',
            itemStyle: {
              borderWidth: 2
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
        color: ['#0FC967', '#37ACFF', '#166EE9', 'rgb(131,175,155)', '#FF69B4', '#FF0000', '#00F5FF']
      }
      return option
    },
    handleDataRankingTbs (num) {
      this.filtroDataRanking = num
      this.handleDataRanking()
    },
    handleDataRanking () {
      this.dataRankingLoading = true
      this.mapFlag = this.path.split('#').length - 1
      switch (this.mapFlag) {
        case 0:
          this.dataRankingTbsList = ['省份', '城市', '项目']
          break
        case 1:
          this.dataRankingTbsList = ['城市', '项目']
          break
        case 2:
          this.dataRankingTbsList = ['项目']
          break
        case 3:
          this.dataRankingTbsList = ['楼层']
          break
        case 4:
          this.dataRankingTbsList = ['店铺']
          break
        default:
          this.dataRankingTbsList = ['店铺']
          break
      }
      const params = {
        groupValue: '',
        path: this.path
      }
      if (
        this.dataRankingTbsList[this.filtroDataRanking] !== '省份' ||
        this.dataRankingTbsList[this.filtroDataRanking] !== '全国'
      ) {
        params.groupValue = this.dataRankingTbsList[this.filtroDataRanking]
      } else {
        params.groupValue = '项目'
      }
      this.axios.post(api.getDataRankingVo, params).then(res => { // 数据排行
        if (res.data.code === 0) {
          const resData = res.data.data
          this.dataRankingObj = resData
          this.dataRankingObj.dataRankingList = resData.dataRankingList.map(item => {
            item.contractArea = item.contractArea + '㎡'
            if (item.incomeThisYear.indexOf('万') !== -1) {
              item.incomeThisYear = item.incomeThisYear.split('万')[0]
            }
            return item
          })
          this.dataRankingLoading = false
        }
      }, rej => {
        this.dataRankingLoading = false
        console.log(rej)
      }).catch(err => {
        this.dataRankingLoading = false
        console.log(err)
      })
    },
    handleRent () { // 收入名目分析
      const params = {
        path: this.path
      }
      this.axios.post(api.getRentAnalysis, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData && resData.length) {
            this.isShowRentCharts = true
            this.$nextTick(() => {
              let isSet = true // 判断是否设置了循环高亮定时器
              let seriesData = [] // echarts series数据
              seriesData = resData.map(item => {
                return {
                  name: item.typeName,
                  value: item.value ? item.value : 0,
                  percent: item.ratio ? item.ratio : 0
                }
              })
              const maxArrList = resData.filter((item, index) => {
                item.index = parseInt(index)
                item.percent = item.ratio
                return item.maxOrNot === 1
              })
              let maxArr = {}
              if (maxArrList && maxArrList.length) {
                maxArr = maxArrList.filter((item, index) => {
                  item.name = item.typeName
                  item.value = item.value ? item.value : 0
                  item.percent = item.ratio ? item.ratio : 0
                  return index === 0
                })[0]
              } else {
                maxArr = {
                  index: 0
                }
              }
              this.chartsPieRent = echarts.init(document.getElementById('chartsPieRent'))
              window.addEventListener('resize', () => {
                this.chartsPieRent.resize()
              })
              this.chartsPieRent.showLoading({
                text: '加载中',
                color: 'rgba(145,213,255,0.85)', // 设置转圈圈字体颜色
                textColor: 'rgba(145,213,255,0.85)', // 设置文字字体颜色
                maskColor: 'rgba(36, 102, 175, 0.05)',
                zlevel: 0
              })
              this.chartsPieRent.setOption(this.chartsOptionPie())
              this.chartsPieRent.setOption({
                title: {
                  text: seriesData[maxArr.index].name,
                  subtext: seriesData[maxArr.index].value ? seriesData[maxArr.index].value : '0'
                },
                series: [
                  {
                    data: seriesData,
                    emphasis: {
                      label: {
                        formatter: [
                          '{b|{b}(万)}',
                          '{c|{c}}',
                          '{d|{d}%}'
                        ].join('\n')
                      }
                    }
                  }
                ]
              })
              this.chartsPieRent.dispatchAction({
                type: 'highlight',
                dataIndex: maxArr.index
              })
              this.chartsPieRent.hideLoading()
              let currentIndex = maxArr.index
              let lastIndex = 0
              const _this = this
              const chartHover = function () { // 循环高亮
                if (seriesData.length !== 1) { // 只有一条的时候，一直高亮唯一一条
                  if (currentIndex + 1 === seriesData.length) { // 序号高位接低位
                    currentIndex = 0
                    lastIndex = seriesData.length - 1
                  } else {
                    currentIndex++
                    lastIndex = currentIndex - 1
                  }
                  _this.chartsPieRent.dispatchAction({
                    type: 'downplay',
                    dataIndex: lastIndex
                  })
                  _this.chartsPieRent.dispatchAction({
                    type: 'highlight',
                    dataIndex: currentIndex
                  })
                }
              }
              this.chartsCirculationRent = setInterval(chartHover, 3000)
              this.chartsPieRent.on('mouseover', params => {
                isSet = false
                clearInterval(this.chartsCirculationRent)
                this.chartsPieRent.dispatchAction({
                  type: 'downplay',
                  dataIndex: currentIndex
                })
                currentIndex = params.dataIndex
                this.chartsPieRent.dispatchAction({
                  type: 'highlight',
                  dataIndex: params.dataIndex
                })
              })
              // 自定义事件
              this.chartsPieRent.on('mouseout', params => {
                if (this.chartsCirculationRent) {
                  clearInterval(this.chartsCirculationRent)
                  isSet = false
                }
                this.chartsPieRent.dispatchAction({
                  type: 'highlight',
                  dataIndex: currentIndex
                })
                if (!isSet) {
                  this.chartsCirculationRent = setInterval(chartHover, 3000)
                  isSet = true
                }
              })
            })
          } else {
            this.isShowRentCharts = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="stylus">
.project-bigscreen
  .w-180>.el-tooltip
    width 180px !important
  .el-table .caret-wrapper
    position absolute
    top -4px
</style>
<style lang="stylus" scoped>
.position-body
  position absolute
  left 0
  top 0
  width 1920px
  height 1080px
  margin 0 auto
  margin-left -860px
  margin-top -540px
.project-bigscreen
  width 100%
  height 100%
  color #fff
  padding 45px 0 0 0
  box-sizing border-box
  background url('~@/assets/images/project-big-srceen.png') top no-repeat
  background-size 100% 77px
  overflow hidden
  .bottom-flex-wrap
    .shadow-box
      margin-bottom 0px !important
  .flex-box
    display flex
    justify-content center
  .page-title
    width 99%
    line-height 77px
    position absolute
    top 0
    font-size 34px
    font-weight 400
    color #FFA134
    text-align center
  .box-0
    width 23.54%
    margin 0 12px 12px 0
  .box-top-inside
    min-height 300px !important
  .box-middle-inside
    min-height 308px !important
  .box-2
    width 23.54%
    margin 0 0 12px 0 !important
  .box-1
    width 50.72%
    margin 0 12px 12px 0
  .shadow-box
    background #23262D
    box-shadow inset 0px 0px 10px 0px rgba(176, 191, 226, 0.15)
    border-radius 2px
    min-height 330px
    position relative
    z-index 2
  .mb-14
    margin-bottom 14px
  .mb-zero
    margin-bottom 0 !important
  .tbsCommon
    height 28px
    line-height 25px
    padding-left 15px
    position absolute
    right 0
    top 0
    background linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 161, 52, 0.3))
    span
      display inline-block
      margin 0 10px
      cursor pointer
      font-size 12px
    span.activeSpan
      color #FFA134
  .icon-exclamation
    margin-left 6px
  .data-card-title
    margin 23px 0 0 25px
    color #70747D
    font-size 14px
  .data-card
    width 90%
    height 87px
    padding 11px 14px
    box-sizing border-box
    margin 6px auto 0 auto
    background linear-gradient(90deg, rgba(40, 41, 48, 0.1), rgba(201, 196, 191, 0.1))
    border-radius 6px
    font-size 14px
    line-height 22px
    position relative
    h3
      font-size 16px
    img
      margin-right 18px
    .tag
      display inline-block
      padding 0 9px
      height 18px
      line-height 18px
      font-size 10px
      background rgba(255, 255, 255, 0.1)
      border-radius 9px
    .fs-10
      display inline-block
      font-size 12px
      transform scale(0.833)
      -webkit-transform scale(0.833)
    .info
      font-size 13px
      color #ABAFBB
    .state
      position absolute
      right 22px
      top 35px
    .state
      &:before
        content ''
        display inline-block
        width 1px
        height 56px
        background #FFFFFF
        opacity 0.1
        position absolute
        right 86px
        top -20px
      i.icon-box
        display inline-block
        width 6px
        height 6px
        border-radius 50%
        position relative
        top -2px
        right 13px
      i.icon-wrap
        display inline-block
        width 12px
        height 12px
        border-radius 50%
        position relative
        right 4px
        top 1px
        opacity 0.1
  .border-r
    border-right 1px solid rgba(255, 255, 255, 0.1)
  .info-ul
    li
      display inline-block
      width 49.5%
      height 51px
      text-align center
      margin-top 24px
      font-weight 500
      color #70747D
      p
        font-size 14px
      .info-p
        width 45%
        margin 0 auto
        text-align left
  .multi-info-ul
    display flex
    flex-wrap wrap
    li
      margin-top 29px !important
      text-align left
      padding-left 73px
      box-sizing border-box
      .second-info
        font-size 12px
        display flex
        span
          font-size 14px
          color rgba(255, 255, 255, 0.45)
  .num-span
    color #fff
    font-size 26px
    font-weight bold
  .charts-box
    width 96%
    height 210px
  #chartsPieRent
    margin-top 30px
  .mt-60
    margin-top 60px
.data-ranking-tbs>>>
  width 95%
  margin 0 auto
  background #23262d !important
  color #fff
  .el-table__header
    width 100% !important
  .el-table__body
    width 100% !important
  td, th
    padding 5px 0
  th, tr, td
    background #23262d !important
    border-bottom 1px solid rgba(255, 255, 255, 0.2) !important
  .el-table__row, .el-table__empty-block, .el-table__expanded-cell
    background #23262d !important
.el-table::before
  height 0 !important
.details-no-data
  padding-bottom 20px
@media screen and (max-width 1800px)
  .project-bigscreen
    .data-card
      .state
        &:before
          width 0
</style>
