<template>
  <div class="map-wrap" v-loading.fullscreen.lock="loading"
    element-loading-text="加载中...">
    <div class="data-head">
      <div class="title">
        项目数据总览 <span class="fr">统计时间：{{time}}</span>
      </div>
      <div class="rent">上月租金(万)</div>
      <div class="data-wrap">
        <div class="month-data">
          {{overViewData.rentLastMonth ? overViewData.rentLastMonth : '-'}}
        </div>
        <div class="area">
          <el-tooltip
            class="item"
            effect="dark"
            content="已签约面积：已签订正式合同的铺位建筑面积总和"
            placement="bottom"
          >
            <div class="name">已签约面积(㎡)</div>
          </el-tooltip>
          <div class="text">{{overViewData.contractAreaSum ? overViewData.contractAreaSum : '-'}}
            <el-tooltip
              class="item"
              effect="dark"
              content="招商完成率：已签订正式合同的铺位建筑面积(去重)/所有铺位建筑面积"
              placement="bottom"
            >
              <em>
                <span>招商完成率:</span> <i class="orange">{{overViewData.vacancyRate?overViewData.vacancyRate:'0.00'}}%</i>
              </em>
            </el-tooltip>
          </div>
        </div>
        <el-tooltip
          class="item"
          effect="dark"
          content="已租铺位数：当前已签约的铺位数总和"
          placement="bottom"
        >
          <div class="area">
            <div class="name">已租铺位数</div>
            <div class="text">{{overViewData.rentedSpaceNumber}}
              <span>环比:</span> <i class="green">{{overViewData.monthOnMonth?overViewData.monthOnMonth:'0.00'}}%</i>
            </div>
          </div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="入驻率：合同已生效的铺位建筑面积/所有铺位建筑面积"
          placement="bottom"
        >
          <div class="area">
            <div class="name">入驻率</div>
            <div class="text">{{overViewData.rentalRate ? overViewData.rentalRate : '-'}}% </div>
          </div>
        </el-tooltip>
      </div>
      <div
        v-if="backShow"
        class="back"
        @click="back()"
      ><span class="el-icon-arrow-left"></span>返回 </div>
    </div>
    <div class="left-data-number">
      <div
        class="row-data"
        v-show="mapHidden"
      >
        <span></span> 管理项目: {{mapNmberData.managingProjectsNumber}}个
      </div>
      <div class="row-data">
        <span></span> 建筑面积: {{mapNmberData.buildAreaTotal}}㎡
      </div>
      <div class="row-data">
        <span></span> 使用面积: {{mapNmberData.usableAreaTotal}}㎡
      </div>
    </div>
    <div class="map-wrap">
      <div
        v-show="mapHidden"
        id="map"
        :style="{width:'100%', height: height + 'px', top: mapType === 'china' ? '180px' : '100px'}"
      ></div>
      <div
        class="img-wrap"
        v-show="imgMapHidden"
      >
        <img
          src="https://img3.winshang.com/wbms/bigScreen-map.png"
          alt=""
          width="80%"
        >
        <div class="building-wrap">
          <div class="building-list">
            <ul>
              <li
                v-for="item in buildingList"
                :key="item.buildingCode"
                @click="toIndoorMap(item)"
              >{{item.buildingName}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="indoor-map"
        v-if="indoorMap"
      >
        <div class="iframe-content">
          <div class="edit-map-building-select2 w-input-sty">
            <el-select
              popper-class="w-block-select-down"
              v-model="buildingId"
              @change="changebuilding"
              placeholder="请选择"
            >
              <el-option
                v-for="item in buildingList"
                :key="item.buildingCode"
                :label="item.buildingName"
                :value="item.buildingCode"
              >
              </el-option>
            </el-select>
          </div>
          <iframe
            id="map"
            :src="iframeUrl"
            frameborder="0"
            width="100%"
            height="500px"
            ref="childrenIframe"
          ></iframe>
        </div>
      </div>
      <div
        v-if="indoorMap"
        class="industry-frame"
      >
        <!-- <div class="industry-item">
          <span>餐饮</span> <i style="background: rgba(110, 71, 34, .7);"></i>
        </div>
        <div class="industry-item">
          <span>零售</span> <i style="background: rgba(36, 85, 101, .7);"></i>
        </div>
        <div class="industry-item">
          <span>儿童亲子</span> <i style="background: rgba(114, 98, 34, .7);"></i>
        </div>
        <div class="industry-item">
          <span>文体娱</span> <i style="background: rgba(21, 80, 39, .7);"></i>
        </div>
        <div class="industry-item">
          <span>生活服务</span> <i style="background: rgba(64, 57, 99, .7);"></i>
        </div>
        <div class="industry-item">
          <span>跨界集合店</span> <i style="background: rgba(20, 64, 99, .7);"></i>
        </div>
        <div class="industry-item">
          <span>其它类型</span> <i style="background: rgba(116, 64, 58, .7);"></i>
        </div> -->
        <div class="positon-a plan-icon-list">
          <template v-for="item in iconList">
            <img
              :key="item.industry"
              :src="item.img"
              alt=""
            >{{item.industry}}
          </template>
        </div>
      </div>
    </div>
    <div
      class="suspension-wrap"
      v-if="showTips"
      :style="{top: fixedTop + 'px', left: fixedLeft + 'px'}"
    >
      <div class="province-name">{{provinceName}}</div>
      <div class="tip-rent"> <span></span> 本月租金 <i>{{selectTipsData.rentThisMonth ? selectTipsData.rentThisMonth : '0.00万'}}</i> </div>
      <div class="tip-sale"> <span></span> 本月销售 <i>{{selectTipsData.salesThisMonth ? selectTipsData.salesThisMonth : '0.00万'}}</i> </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import api from '@/api'
import dayjs from 'dayjs'
import { getCookie } from '@/utils'
export default {
  data () {
    return {
      time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      timer: null,
      myChart: null,
      mapCaches: {},
      height: 620,
      path: '全国',
      mapType: 'china',
      countryData: [],
      cityData: [],
      fixedLeft: 0,
      fixedTop: 0,
      showTips: false,
      provinceName: '',
      selectTipsData: {},
      properties: {},
      overViewData: {},
      mapNmberData: {},
      mapTitle: '全国',
      tmpSeriesData: [],
      mapHidden: true,
      imgMapHidden: false,
      indoorMap: false,
      buildingList: [],
      projectId: 0,
      iframeUrl: '',
      childrenIframe: null,
      type: null,
      iconList: [],
      backShow: false,
      loading: false
    }
  },
  mounted () {
    this.init()
    this.getOverviewValuesFunc()
    this.getMapValueFunc()
    // this.getProjectDistributionFunc()
    this.$nextTick(() => {
      this.getIndustryIconFunc()
    })
    this.timer = setInterval(() => {
      this.time = dayjs().format('YYYY-MM-DD HH:mm:ss')
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
    window.removeEventListener('message', this.postMessage)
  },
  methods: {
    getIndustryIconFunc () {
      this.axios.post(api.getIndustryIcon)
        .then((res) => {
          this.iconList = res.data.data
        })
    },
    // 项目分布类型：0无项目
    // 1单项目
    // 2两个以上项目在同一个市
    // 3两个以上项目在同一个省
    // 4两个以上项目不在同一个省
    getProjectDistributionFunc () {
      this.axios.post(api.projectDistribution).then((res) => {
        this.projectDataType = res.data.data
        this.type = res.data.data.type
        if (res.data.data.type === 0) {

        } else if (res.data.data.type === 1) {
          this.mapHidden = false
          this.imgMapHidden = true
          this.indoorMap = false
          const splitArr2 = res.data.data.adCode.split('#')
          this.projectId = splitArr2[3]
          const pathStr = res.data.data.value.split('#')
          this.mapTitle = pathStr[3]
          let provinceStr = pathStr[1]
          if (pathStr[1] === '广西壮族自治区') {
            provinceStr = '广西'
          } else if (pathStr[1] === '内蒙古自治区') {
            provinceStr = '内蒙古'
          } else if (pathStr[1] === '新疆维吾尔自治区') {
            provinceStr = '新疆'
          } else if (pathStr[1] === '西藏自治区') {
            provinceStr = '西藏'
          } else if (pathStr[1] === '香港特别行政区') {
            provinceStr = '香港'
          } else if (pathStr[1] === '澳门特别行政区') {
            provinceStr = '澳门'
          } else if (pathStr[1] === '重庆市') {
            provinceStr = '重庆'
          } else if (pathStr[1] === '北京市') {
            provinceStr = '北京'
          } else if (pathStr[1] === '上海市') {
            provinceStr = '上海'
          } else if (pathStr[1] === '天津市') {
            provinceStr = '天津'
          } else {
            provinceStr = provinceStr.replace('省', '')
          }
          this.path = '全国#' + provinceStr + '#' + pathStr[2] + '#' + pathStr[3]
          this.$emit('selectPath', this.path)
          this.getGdBuildingFunc()
          this.getOverviewValuesFunc()
          this.getMapValueFunc()
        } else if (res.data.data.type === 2) {
          this.properties.level = 'city'
          const splitArr = res.data.data.value.split('#')
          const splitArr2 = res.data.data.adCode.split('#')
          this.mapTitle = splitArr[2]
          const pathStr = res.data.data.value.split('#')
          let provinceStr = pathStr[1]
          if (pathStr[1] === '广西壮族自治区') {
            provinceStr = '广西'
          } else if (pathStr[1] === '内蒙古自治区') {
            provinceStr = '内蒙古'
          } else if (pathStr[1] === '新疆维吾尔自治区') {
            provinceStr = '新疆'
          } else if (pathStr[1] === '西藏自治区') {
            provinceStr = '西藏'
          } else if (pathStr[1] === '香港特别行政区') {
            provinceStr = '香港'
          } else if (pathStr[1] === '澳门特别行政区') {
            provinceStr = '澳门'
          } else if (pathStr[1] === '重庆市') {
            provinceStr = '重庆'
          } else if (pathStr[1] === '北京市') {
            provinceStr = '北京'
          } else if (pathStr[1] === '上海市') {
            provinceStr = '上海'
          } else if (pathStr[1] === '天津市') {
            provinceStr = '天津'
          } else {
            provinceStr = provinceStr.replace('省', '')
          }
          this.path = '全国#' + provinceStr + '#' + pathStr[2]
          this.getMapDataNumberFunc().then((res) => {
            this.cityData = res.data.data
            this.loadMap(splitArr2[2], splitArr[2], {
              code: splitArr2[2],
              isFull: false
            })
          })
          // console.log(this.path)
          this.$emit('selectPath', this.path)
          this.getOverviewValuesFunc()
          this.getMapValueFunc()
        } else if (res.data.data.type === 3) {
          const splitArr = res.data.data.value.split('#')
          const splitArr2 = res.data.data.adCode.split('#')
          this.mapTitle = splitArr[1]
          const pathStr = res.data.data.value.split('#')
          let provinceStr = pathStr[1]
          if (pathStr[1] === '广西壮族自治区') {
            provinceStr = '广西'
          } else if (pathStr[1] === '内蒙古自治区') {
            provinceStr = '内蒙古'
          } else if (pathStr[1] === '新疆维吾尔自治区') {
            provinceStr = '新疆'
          } else if (pathStr[1] === '西藏自治区') {
            provinceStr = '西藏'
          } else if (pathStr[1] === '香港特别行政区') {
            provinceStr = '香港'
          } else if (pathStr[1] === '澳门特别行政区') {
            provinceStr = '澳门'
          } else if (pathStr[1] === '重庆市') {
            this.properties.level = 'city'
            provinceStr = '重庆'
          } else if (pathStr[1] === '北京市') {
            this.properties.level = 'city'
            provinceStr = '北京'
          } else if (pathStr[1] === '上海市') {
            this.properties.level = 'city'
            provinceStr = '上海'
          } else if (pathStr[1] === '天津市') {
            this.properties.level = 'city'
            provinceStr = '天津'
          } else {
            provinceStr = provinceStr.replace('省', '')
          }
          if (['重庆市', '北京市', '上海市', '天津市'].includes(pathStr[1])) {
            this.path = '全国#' + provinceStr + '#' + pathStr[1]
          } else {
            this.path = '全国#' + provinceStr
          }
          this.getMapDataNumberFunc().then((res) => {
            this.cityData = res.data.data
            this.loadMap(splitArr2[1], splitArr[1], {
              code: splitArr2[1],
              isFull: true
            })
          })
          this.$emit('selectPath', this.path)
          this.getOverviewValuesFunc()
          this.getMapValueFunc()
        } else if (res.data.data.type === 4) {
          this.path = '全国'
          this.loadMap('china', '中国', {
            code: 100000,
            isFull: true
          })
          this.$emit('selectPath', this.path)
          this.getOverviewValuesFunc()
          this.getMapValueFunc()
        }
      })
    },
    back () {
      this.loading = true
      window.location.reload()
      this.loading = false
      // if (this.type === 1) {
      //   this.backShow = false
      //   this.mapHidden = false
      //   this.imgMapHidden = true
      //   this.indoorMap = false
      //   const splitArr2 = this.projectDataType.adCode.split('#')
      //   this.projectId = splitArr2[3]
      //   const pathStr = this.projectDataType.value.split('#')
      //   this.mapTitle = pathStr[3]
      //   let provinceStr = pathStr[1]
      //   if (pathStr[1] === '广西壮族自治区') {
      //     provinceStr = '广西'
      //   } else if (pathStr[1] === '内蒙古自治区') {
      //     provinceStr = '内蒙古'
      //   } else if (pathStr[1] === '新疆维吾尔自治区') {
      //     provinceStr = '新疆'
      //   } else if (pathStr[1] === '西藏自治区') {
      //     provinceStr = '西藏'
      //   } else if (pathStr[1] === '香港特别行政区') {
      //     provinceStr = '香港'
      //   } else if (pathStr[1] === '澳门特别行政区') {
      //     provinceStr = '澳门'
      //   } else if (pathStr[1] === '重庆市') {
      //     provinceStr = '重庆'
      //   } else if (pathStr[1] === '北京市') {
      //     provinceStr = '北京'
      //   } else if (pathStr[1] === '上海市') {
      //     provinceStr = '上海'
      //   } else if (pathStr[1] === '天津市') {
      //     provinceStr = '天津'
      //   } else {
      //     provinceStr = provinceStr.replace('省', '')
      //   }
      //   this.cityData = []
      //   this.countryData = []
      //   this.path = '全国#' + provinceStr + '#' + pathStr[2] + '#' + pathStr[3]
      //   this.$emit('selectPath', this.path)
      //   this.getGdBuildingFunc()
      //   this.getOverviewValuesFunc()
      //   this.getMapValueFunc()
      // } else if (this.type === 2) {
      //   this.backShow = false
      //   this.mapHidden = true
      //   this.imgMapHidden = false
      //   this.indoorMap = false
      //   this.properties.level = 'city'
      //   const splitArr = this.projectDataType.value.split('#')
      //   const splitArr2 = this.projectDataType.adCode.split('#')
      //   this.mapTitle = splitArr[2]
      //   const pathStr = this.projectDataType.value.split('#')
      //   let provinceStr = pathStr[1]
      //   if (pathStr[1] === '广西壮族自治区') {
      //     provinceStr = '广西'
      //   } else if (pathStr[1] === '内蒙古自治区') {
      //     provinceStr = '内蒙古'
      //   } else if (pathStr[1] === '新疆维吾尔自治区') {
      //     provinceStr = '新疆'
      //   } else if (pathStr[1] === '西藏自治区') {
      //     provinceStr = '西藏'
      //   } else if (pathStr[1] === '香港特别行政区') {
      //     provinceStr = '香港'
      //   } else if (pathStr[1] === '澳门特别行政区') {
      //     provinceStr = '澳门'
      //   } else if (pathStr[1] === '重庆市') {
      //     provinceStr = '重庆'
      //   } else if (pathStr[1] === '北京市') {
      //     provinceStr = '北京'
      //   } else if (pathStr[1] === '上海市') {
      //     provinceStr = '上海'
      //   } else if (pathStr[1] === '天津市') {
      //     provinceStr = '天津'
      //   } else {
      //     provinceStr = provinceStr.replace('省', '')
      //   }
      //   this.path = '全国#' + provinceStr + '#' + pathStr[2]
      //   this.getMapDataNumberFunc().then((res) => {
      //     this.cityData = res.data.data
      //     this.loadMap(splitArr2[2], splitArr[2], {
      //       code: splitArr2[2],
      //       isFull: false
      //     })
      //   })
      //   // console.log(this.path)
      //   this.cityData = []
      //   this.countryData = []
      //   this.$emit('selectPath', this.path)
      //   this.getOverviewValuesFunc()
      //   this.getMapValueFunc()
      // } else if (this.type === 3) {
      //   this.backShow = false
      //   this.mapHidden = true
      //   this.imgMapHidden = false
      //   this.indoorMap = false
      //   const splitArr = this.projectDataType.value.split('#')
      //   const splitArr2 = this.projectDataType.adCode.split('#')
      //   this.mapTitle = splitArr[1]
      //   const pathStr = this.projectDataType.value.split('#')
      //   let provinceStr = pathStr[1]
      //   if (pathStr[1] === '广西壮族自治区') {
      //     provinceStr = '广西'
      //   } else if (pathStr[1] === '内蒙古自治区') {
      //     provinceStr = '内蒙古'
      //   } else if (pathStr[1] === '新疆维吾尔自治区') {
      //     provinceStr = '新疆'
      //   } else if (pathStr[1] === '西藏自治区') {
      //     provinceStr = '西藏'
      //   } else if (pathStr[1] === '香港特别行政区') {
      //     provinceStr = '香港'
      //   } else if (pathStr[1] === '澳门特别行政区') {
      //     provinceStr = '澳门'
      //   } else if (pathStr[1] === '重庆市') {
      //     provinceStr = '重庆'
      //   } else if (pathStr[1] === '北京市') {
      //     provinceStr = '北京'
      //   } else if (pathStr[1] === '上海市') {
      //     provinceStr = '上海'
      //   } else if (pathStr[1] === '天津市') {
      //     provinceStr = '天津'
      //   } else {
      //     provinceStr = provinceStr.replace('省', '')
      //   }
      //   this.path = '全国#' + provinceStr
      //   this.getMapDataNumberFunc().then((res) => {
      //     this.cityData = res.data.data
      //     // this.cityData = []
      //     // this.countryData = []
      //     this.loadMap(splitArr2[1], splitArr[1], {
      //       code: splitArr2[1],
      //       isFull: true
      //     })
      //   })
      //   this.cityData = []
      //   this.countryData = []
      //   this.$emit('selectPath', this.path)
      //   this.getOverviewValuesFunc()
      //   this.getMapValueFunc()
      // } else if (this.type === 4) {
      //   this.backShow = false
      //   this.mapHidden = true
      //   this.imgMapHidden = false
      //   this.indoorMap = false
      //   this.properties = {}
      //   this.path = '全国'
      //   this.mapTitle = '全国'
      //   this.getMapDataNumberFunc().then((res) => {
      //     this.cityData = res.data.data
      //     this.loadMap('china', '中国', {
      //       code: 100000,
      //       isFull: true
      //     })
      //     this.$emit('selectPath', this.path)
      //     this.getOverviewValuesFunc()
      //     this.getMapValueFunc()
      //   })
      // }

      // if (this.type === 4) {
      //   this.path = '全国'
      //   this.getMapDataNumberFunc().then((res) => {
      //     this.cityData = res.data.data
      //     this.loadMap('china', '中国', {
      //       code: 100000,
      //       isFull: true
      //     })
      //   })
      //   this.mapTitle = '全国'
      //   this.properties = {}
      //   this.$emit('selectPath', this.path)
      // this.getOverviewValuesFunc()
      // this.getMapValueFunc()
      //   this.showTips = false
      //   this.mapHidden = true
      //   this.imgMapHidden = false
      //   this.indoorMap = false
      //   this.getOverviewValuesFunc()
      //   this.getMapValueFunc()
      // } else {
      //   this.getProjectDistributionFunc()
      // }
    },
    init () {
      this.axios.post(api.getMapManagement, {
        path: this.path
      }).then((res) => {
        this.countryData = res.data.data
        this.cityData = res.data.data
        this.getProjectDistributionFunc()
      })
    },
    getGdBuildingFunc () {
      this.axios.post(api.getGdbuilding, { projectId: this.projectId })
        .then((res) => {
          this.buildingList = res.data.data
        })
    },
    toIndoorMap (item) {
      this.backShow = true
      this.mapHidden = false
      this.imgMapHidden = false
      this.indoorMap = true
      this.$nextTick(() => {
        this.childrenIframe = this.$refs.childrenIframe.contentWindow
        window.addEventListener('message', this.postMessage, false)
      })
      this.projectId = item.projectId
      this.buildingId = item.buildingCode
      this.reloadMap()
    },
    changebuilding () {
      this.reloadMap()
    },
    postMessage (e) {
      let path = ''
      if (e.data.floor.indexOf('B') === -1) {
        path = this.path + '#' + this.buildingId + '#' + 'F' + e.data.floor
      } else {
        path = this.path + '#' + this.buildingId + '#' + e.data.floor
      }
      // console.log(path)
      if (e.data.floor) {
        this.$emit('selectPath', path)
        this.getOverviewValuesFunc(path)
        this.getMapValueFunc(path)
      }
    },
    reloadMap () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://localhost:8001'
      } else if (window.location.href.indexOf('http://') > -1) {
        temUrl = 'http://' + document.domain + '/map'
      } else {
        temUrl = 'https://' + document.domain + '/map'
      }
      this.iframeUrl = temUrl + '/screen.html?token=' +
        getCookie('wisdom_token') +
        '&projectId=' + this.projectId +
        '&buildingId=' + this.buildingId +
        '&taskId=11&type=1'
    },
    getMapDataNumberFunc () {
      return new Promise((resolve) => {
        this.axios.post(api.getMapManagement, {
          path: this.path
        }).then((res) => {
          if (res.status === 200) {
            resolve(res)
          }
        })
      })
    },
    getOverviewValuesFunc (path) {
      let pathVal = this.path
      if (path) {
        pathVal = path
      }
      this.axios.post(api.getOverviewValues, {
        path: pathVal
      }).then((res) => {
        if (res.data.data) {
          this.overViewData = res.data.data
        } else {
          this.overViewData = {}
        }
        // console.log('getOverviewValuesFunc', res.data.data)
      })
    },
    getMapValueFunc (path) {
      let pathVal = this.path
      if (path) {
        pathVal = path
      }
      this.axios.post(api.getMapValue, {
        path: pathVal
      }).then((res) => {
        this.mapNmberData = res.data.data
        // console.log('getMapValueFunc', res.data.data)
      })
    },
    getAreaMap (areaCode, isFull) {
      return this.axios
        .get('https://jscss.winshangdata.com/yzs/province/' + areaCode + (isFull ? '_full' : '') + '.json')
        .then(function (res) {
          return Promise.resolve(res.data)
        })
    },
    loadMap (map, name, { code, isFull }) {
      console.log(map, name)
      let promise = Promise.resolve()
      if (!this.mapCaches[map]) {
        promise = this.getAreaMap(code, isFull).then((mapData) => {
          echarts.registerMap(map, mapData)
          this.mapCaches[map] = mapData
        })
      }
      promise.then(() => {
        this.myChart = echarts.init(document.getElementById('map'))
        this.mapType = map
        // let tmpSeriesData = []
        const regions = []
        for (const item of this.cityData) {
          const obj = {}
          if (this.mapTitle === '全国') {
            if (item.name === '广西') {
              obj.name = '广西壮族自治区'
            } else if (item.name === '内蒙古') {
              obj.name = '内蒙古自治区'
            } else if (item.name === '新疆') {
              obj.name = '新疆维吾尔自治区'
            } else if (item.name === '西藏') {
              obj.name = '西藏自治区'
            } else if (item.name === '香港') {
              obj.name = '香港特别行政区'
            } else if (item.name === '澳门') {
              obj.name = '澳门特别行政区'
            } else if (item.name === '重庆') {
              obj.name = '重庆市'
            } else if (item.name === '北京') {
              obj.name = '北京市'
            } else if (item.name === '上海') {
              obj.name = '上海市'
            } else if (item.name === '天津') {
              obj.name = '天津市'
            } else {
              obj.name = item.name + '省'
            }
          } else {
            obj.name = item.name
          }
          obj.itemStyle = {
            normal: {
              opacity: 1, // 透明度
              borderColor: '#fff', // 省份界线颜色
              borderWidth: 0, // 省份界线的宽度
              areaColor: '#FFA134' // 整个省份的颜色
            }
          }
          regions.push(obj)
        }
        regions.push({
          name: '南海诸岛',
          itemStyle: {
            // 隐藏地图
            normal: {
              opacity: 1 // 为 0 时不绘制该图形
            }
          },
          label: {
            show: false // 隐藏文字
          }
        })
        console.log(this.properties)
        this.tmpSeriesData = []
        if (this.properties.level === 'city' || (this.properties.level === 'province' && ['重庆市', '北京市', '上海市', '天津市'].includes(name))) {
          this.tmpSeriesData = []
          for (var i = 0; i < this.cityData.length; i++) {
            const obj = {}
            obj.name = this.cityData[i].name
            obj.rentThisMonth = this.cityData[i].rentThisMonth ? this.cityData[i].rentThisMonth : '0.00万'
            obj.salesThisMonth = this.cityData[i].salesThisMonth ? this.cityData[i].salesThisMonth : '0.00万'
            obj.mallCode = this.cityData[i].mallCode
            obj.type = true
            obj.value = []
            if (obj.name) {
              obj.value.push(this.cityData[i].longitude)
              obj.value.push(this.cityData[i].latitude)
            }
            this.tmpSeriesData.push(obj)
          }
        } else {
          this.tmpSeriesData = []
        }
        // console.log(tmpSeriesData)
        const option = {
          tooltip: {
            trigger: 'item',
            formatter: (params) => {
              if (params.data.type) {
                // console.log(params)
                return '<span style="color: #FFA134">' + params.name + '</span>' + '<br/>' + '本月租金: ' + '<span style="color: #FFA134">' + (params.data.rentThisMonth ? params.data.rentThisMonth : '-') + '</span><br/>' + '本月销售: <span style="color: #FFA134">' + (params.data.salesThisMonth ? params.data.salesThisMonth : '-') + '</span>'
              }
            },
            showContent: true,
            padding: [5, 10]
          },
          // visualMap: {
          // type: 'piecewise',
          // textStyle: {
          //   color: '#fff'
          // },
          // color: ['#e3bf4c', '#be4f51', '#60c2cc']
          // },
          geo: {
            map: map,
            zoom: map === 'china' ? 1.25 : 1,
            label: {
              emphasis: {
                show: true
              }
            },
            itemStyle: {
              normal: {
                areaColor: '#39404b',
                borderColor: '#111',
                textColor: '#ffffff'
              },
              emphasis: {
                areaColor: '#FFA134',
                color: '#ffffff',
                textStyle: {
                  color: 'rgba(255,255,255,1)'
                }
              }
            },
            regions: regions
            // regions: [
            //   {
            //     name: '南海诸岛',
            //     itemStyle: {
            //       // 隐藏地图
            //       normal: {
            //         opacity: 0 // 为 0 时不绘制该图形
            //       }
            //     },
            //     label: {
            //       show: false // 隐藏文字
            //     }
            //   },
            //   { // 重点 部分 ,在这里给大家模拟一个省份颜色与界线颜色的修改,如果想修改多个省份就在后面多添加几个对象即可.
            //     name: '广东省', // 对应的是import "./china"  数据中的名称如: name: "广东省"(下面有截图)
            //     itemStyle: {
            //       normal: {
            //         opacity: 1, // 透明度
            //         borderColor: '#fff', // 省份界线颜色
            //         borderWidth: 0, // 省份界线的宽度
            //         areaColor: '#FFA134' // 整个省份的颜色
            //       }
            //     }
            //   }
            // ]
          },
          series: [
            {
              name: name,
              // type: 'map',
              // map,
              type: 'scatter',
              coordinateSystem: 'geo',
              roam: false, // 是否开启鼠标缩放和平移漫游
              geoIndex: 0,
              itemStyle: {
                // 地图区域的多边形 图形样式
                color: '#fff',
                normal: {
                  // 是图形在默认状态下的样式
                  label: {
                    show: true, // 是否显示标签
                    textStyle: {
                      color: 'rgba(255,255,255,0)'
                    }
                  }
                }
              },
              data: this.tmpSeriesData,
              symbolSize: 12,
              label: {
                normal: {
                  show: true
                },
                emphasis: {
                  areaColor: ['#FF9D3F'],
                  show: true
                }
              },
              aspectScale: map === 'china' ? 0.8 : 1,
              top: '10%' // 组件距离容器的距离
            }
          ]
        }
        let paramName = ''
        this.myChart.setOption(option)
        this.myChart.off('click')
        this.myChart.off('dblclick')
        this.myChart.on('click', (param) => {
          console.log(param)
          console.log(this.properties)
          this.backShow = true
          if (this.properties.name === '北京市' ||
            this.properties.name === '天津市' ||
            this.properties.name === '重庆市' ||
            this.properties.name === '上海市') {
            this.properties.level = 'city'
          }
          if (this.properties.level !== 'city') {
            this.mapTitle = param.name
          } else if (this.properties.level === 'city') {
            if (param.data.mallCode) {
              this.mapTitle = param.name
              this.imgMapHidden = true
              this.mapHidden = false
              this.projectId = param.data.mallCode
              this.getGdBuildingFunc()
            }
          }
          paramName = param.name
          if (this.properties.level === undefined) {
            if (param.name === '广西壮族自治区') {
              paramName = '广西'
            } else if (param.name === '内蒙古自治区') {
              paramName = '内蒙古'
            } else if (param.name === '新疆维吾尔自治区') {
              paramName = '新疆'
            } else if (param.name === '西藏自治区') {
              paramName = '西藏'
            } else if (param.name === '香港特别行政区') {
              paramName = '香港'
            } else if (param.name === '澳门特别行政区') {
              paramName = '澳门'
            } else if (param.name === '重庆市') {
              paramName = '重庆'
            } else if (param.name === '北京市') {
              paramName = '北京'
            } else if (param.name === '上海市') {
              paramName = '上海'
            } else if (param.name === '天津市') {
              paramName = '天津'
            } else {
              paramName = paramName.replace('省', '')
            }
          }
          if (['重庆市', '北京市', '上海市', '天津市'].includes(param.name)) {
            this.path += '#' + paramName + '#' + param.name
          } else {
            this.path += '#' + paramName
          }
          // 请求地图点击之后的数据
          this.$emit('selectPath', this.path)
          this.getOverviewValuesFunc()
          this.getMapValueFunc()
          this.getMapDataNumberFunc().then((res) => {
            // console.log(this.properties)
            if (this.properties.level !== 'city') {
              this.cityData = res.data.data
              const mapData = this.mapCaches[map]
              if (mapData) {
                const mapInfo = mapData.features.find(m => {
                  return m.properties.name === param.name
                })
                this.properties = mapInfo.properties
                this.loadMap(this.properties.adcode, this.properties.name, {
                  code: this.properties.adcode,
                  isFull: this.properties.level !== 'city'
                })
              }
            }
          })
        })
        this.myChart.on('mouseover', (param) => {
          // console.log(param)
          // console.log(this.properties.level)
          if (this.properties.level !== 'city') {
            this.selectTipsData = {}
            for (const item of this.cityData) {
              if (param.name.indexOf(item.name) !== -1) {
                this.selectTipsData = item
              }
            }
            this.provinceName = param.name
            this.showTips = true
            this.fixedLeft = param.event.offsetX + 40
            this.fixedTop = param.event.offsetY + 80
          }
        })
        this.myChart.on('mouseout', () => {
          this.showTips = false
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.map-wrap
  position relative
  // overflow hidden
  height 100%
  .left-data-number
    position absolute
    left 0
    bottom 0
    z-index 111
    .row-data
      font-size 14px
      font-weight 400
      color #FFFFFF
      margin 5px 0
      span
        display inline-block
        width 6px
        height 6px
        background #FFA134
        border-radius 50%
        vertical-align middle
  .data-head
    width 100%
    position absolute
    top 48px
    z-index 1111
    .title
      font-size 18px
      font-weight 500
      span
        font-size 14px
        color rgba(255, 255, 255, 0.3)
    .rent
      font-size 14px
      color #70747D
      font-weight 500
      margin-top 8px
    .data-wrap
      display flex
      .month-data
        width 137px
        height 59px
        background #282930
        box-shadow 0px 0px 10px 0px rgba(176, 191, 226, 0.15)
        border-radius 4px
        font-size 35px
        font-weight bold
        color #fff
        text-align center
        line-height 59px
        margin-top 5px
        background url('~@/assets/images/numBg.png') no-repeat
        margin-right 48px
      .area
        margin-top 7px
        margin-right 48px
        .name
          font-size 14px
          font-weight 500
          color #70747D
        .text
          font-size 26px
          font-weight bold
          color #FFFFFF
          span
            font-size 12px
            font-weight 500
            color #70747D
          .orange
            color #FF5A06
            font-size 12px
            font-weight 500
          .green
            color #07C43E
            font-size 12px
            font-weight 500
    .back
      position relative
      z-index 11
      margin-top 20px
      cursor pointer
  .map-wrap
    // #map
    // width 300px
    // height 400px
.suspension-wrap
  position absolute
  width 180px
  height 100px
  background-color rgba(0, 0, 0, 0.8)
  border-radius 6px
  padding-left 17px
  padding-top 15px
  z-index 11
  .province-name
    font-size 18px
    font-weight 500
    color #FFA134
  .tip-rent, .tip-sale
    font-size 14px
    font-weight 500
    color #FFFFFF
    margin 6px 0
    span
      display inline-block
      width 6px
      height 6px
      background #FFA134
      border-radius 50%
      vertical-align middle
    i
      color #FFA134
.img-wrap
  height 100%
  width 100%
  background url('https://img3.winshang.com/wbms/bigScreen-bg.png') no-repeat
  background-size 190%
  background-position center 13%
  text-align center
  img
    margin-top 19%
    text-align center
.building-wrap
  // position absolute
  // bottom 20px
  height 28px
  // left 50%
  // min-width 100px
  // margin-left -50px
  // background linear-gradient(90deg, rgba(255, 161, 52, 0.98))
  // background linear-gradient(to right, rgba(255, 161, 52, 0.3), rgba(255, 255, 255, 0))
  background linear-gradient(to right, rgba(0, 0, 0, 0.01) 30%, rgba(255, 161, 52, 0.5) 50%, rgba(0, 0, 0, 0.01) 70%)
  text-align center
  .building-list
    display flex
    justify-items center
    justify-content center
    ul
      display flex
      justify-items center
      justify-content center
      li
        padding 0 15px
        font-size 14px
        color rgba(255, 255, 255, 1)
        line-height 28px
        text-align center
        cursor pointer
        &:hover
          color #FFA134
.iframe-content
  position absolute
  top 195px
  width 100%
.industry-frame
  position absolute
  display flex
  bottom 0px
  left 50%
  margin-left -300px
  z-index 11
  span
    font-size 14px
  i
    display inline-block
    width 10px
    height 10px
    border-radius 50%
    margin-right 10px
  .plan-icon-list
    // position absolute
    // bottom 30px
    // left 50%
    font-size 14px
    color #fff
    font-weight 500
    // margin auto
    text-align center
    // margin-left -286px
    position relative
    margin-top -40px
    img
      margin-right 5px
      margin-left 10px
      width 18px
      height 18px
      vertical-align middle
</style>
